<template>
  <div class="ml-5 mr-5">
    <div class="text-right my-4">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
    </div>

    <div class="text-right"></div>
 <!-- v-data-table  -->

<!-- <d-player :options2="options2"
              @play="play"
              ref="player">
    </d-player> -->

    <v-row>
      <v-col cols="12">

          <div class="tableWrapper">

            <v-data-table
              :headers="headers"
              item-key="id"
              :single-select="singleSelect"
              show-select
              v-model="selected"
              :items="mobiledata"
              :options.sync="options"
              :server-items-length="total"
              :loading="isLoading"
              class="elevation-1"
              hide-default-footer
            >
              <template v-slot:item.mapping_sectors="{ item }">
                <div>
                  <v-icon @click="mapping_sectors(item)"  small class="mr-2" title="View mapped sectors">mdi-eye</v-icon>
                  <v-icon small class="mr-2" @click="add_mapping_sector(item)" title="Add Sectors">mdi-plus</v-icon>
                  <!-- <v-btn color="primary" small @click.stop = "mapping_sectors(item)" >View Sectors</v-btn>
                  <v-btn color="primary" style="margin-left:20px" small @click.stop = "add_mapping_sector(item)" >Add Sectors</v-btn></div> -->
                </div>
              </template>
         <template v-slot:top>
                  <v-row no-gutters >
                              <v-col cols="12" class="mb-4">
                              <Tabs/>
                              <div style="float:right"><v-btn color="error" small @click.stop = "deletesection()" >Delete</v-btn></div>
                              <div style="float:right"><v-btn color="primary" small @click.stop = "openaddsection()" >Add</v-btn></div>

                              </v-col>

                                   </v-row>
                </template>
            </v-data-table>
             <div class="text-center pt-4 pb-4">
              <Pagination
            :isloading="isLoading"
            :startRecord="startRecord"
            :currentPage="currentPage"
            :lastPage="lastPage"
            :lastRecord="lastRecord"
            :totRecords="totRecords"
            :isCurrentPageClass="isCurrentPageClass"
            :perpage="perpage"
            :getLastPageClass="getLastPageClass"
            :totPage="totPage"
            :getPages="getPages"
            @handlePerPage="handlePerPage"
            @paginate="paginate"
            @last="last"
            @getDataByPage="getDataByPage"
            :showPerPage="showPerPage"
             />
            </div>
          </div>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="addsectiondialog" max-width="650">
      <v-card  class="pa-md-4 mx-lg-auto">
        <v-card-title class="text-h5 grey lighten-2">
                            Add Section
                            </v-card-title>


    <v-text-field
      v-model="section_name"
      :counter="10"
      :rules="nameRules"
      label="Name"
      required
    ></v-text-field>
     <v-select
          v-model="sector_id"
          :items="sectordata"
           item-text="sectorName"
          item-value="sectorID"
          :menu-props="{ maxHeight: '400' }"
          label="Select"
          multiple
          persistent-hint
        ></v-select>



    <v-btn
      color="primary"
      class="mr-4"
      small
      @click="save"
    >
      Save
    </v-btn>

    <v-btn
      color="primary"
      small
      @click="cancel"
    >
      Cancel
    </v-btn>



      </v-card>

    </v-dialog>

<v-dialog persistent v-model="mappingsectordialog" max-width="650">
      <v-card  class="pa-md-4 mx-lg-auto">
        <v-card-title class="text-h5 grey lighten-2">
                            Mapping Sector
        </v-card-title>
     <v-select
          v-model="sector_id"
          :items="sectordata"
           item-text="sectorName"
          item-value="sectorID"
          :menu-props="{ maxHeight: '400' }"
          label="Select"
          multiple
          persistent-hint
        ></v-select>



    <v-btn
      color="primary"
      class="mr-4"
      small
      @click="savemapping"
    >
      Save
    </v-btn>

    <v-btn
      color="primary"
      small
      @click="cancelmapping"
    >
      Cancel
    </v-btn>



      </v-card>

    </v-dialog>






    <v-dialog v-model="sectormappingdialog" max-width="650">
      <v-card  class="pa-md-4 mx-lg-auto">
        <v-card-title class="text-h5 grey lighten-2">
                            Sectors
                            </v-card-title>
                             <v-data-table
              :headers="sectorsheaders"
              :items="mapped_sectors_data"
              :options.sync="options"
              :server-items-length="total"
              :loading="isLoading"
              class="elevation-1"
              hide-default-footer
            >
            <template v-slot:item.action="{ item }">
                <div>
                  <v-icon small class="mr-2" @click="delete_mapping_sectors(item)"  v-show="checkIfOperationExistForModule('delete')" title="Delete">mdi-delete</v-icon>
                  </div>
              </template>

            </v-data-table>

      </v-card>

    </v-dialog>
</div>


</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import Pagination from "./component/Pagination.vue";
// import videoPlayer from 'vuetify-media-player/src/components/video-player.vue'
// import 'vuetify-media-player/src/style.styl'
// import VueDPlayer from 'vue-dplayer'
// import 'https://unpkg.com/browse/vue-dplayer@0.0.10/dist/vue-dplayer.css'
import MobileDigitalTemp from "./component/MobileDigitalTemp";
export default {
  // components: { Index, Buttons, Loading, Pagination,videoPlayer },
  components: { Index, Buttons, Pagination,MobileDigitalTemp },
  data() {
    return {
      mappingsectordialog:false,
      add_mapping_sector_status:false,
      mapping_section_id:'',
      section_name:'',
      sector_id:'',
      sectormappingdialog:false,
      sectordata:[{"sectorName":"Insurance","sector_id":"4"}],

      addsectiondialog:false,
      mappingsectiondialog:false,
      toggleUpdateModal:false,
      //////table data starts here////
      selected: [],
      singleSelect: false,
      digital_channel:['All','Online Display','Search Engine Marketing','Online Video'],
      digital_channel_data:'All',
      options: {},
      sort: "",
      total: 0,
      mapped_sectors_data :[],
      editReportMode: false,
      fromDateMenu: false,
      fromDateVal: null,
      //  minDate: "2020-01-05",
      //  maxDate: "2019-08-30",
      sectorsheaders:[
        {
                    text: 'Name',
                    align: 'start',
                    value: 'sector_name',
                    class: "v-data-table-header",
                     width:"500px",
        },
        {
                    text: 'Action',
                    align: 'start',
                    value: 'action',
                    class: "v-data-table-header",
                     width:"10px",

        }

      ],

      headers: [

                {
                    text: 'Sections',
                    align: 'start',
                    value: 'section_name',
                    class: "v-data-table-header",
                    width:"850px",

                },
                {
                    text: 'Action',
                    value: 'mapping_sectors',

                    class: "v-data-table-header",
                }

            ],

      ////// table data ends here
      valid: true,
      checkbox: false,
      operation: [],
      modules: [],
      mobiledata: [],
      isLoading: true,
      fullPage: true,
      dialog: false,
      dialog1: false,
      dialog2: false,
      deleteItems: [],
      checked_Id: [],
      checkcedstr: "",
      message1: "",
      message2: "",
      upmessage1: "",
      upmessage2: "",
      parent_id: [],
      module_sort: [],
      allSelected: false,
      new: [],
      panel_data: [],
      addModule: {
        moduleName: null,
        parent_id: null,
        module_icon: "mdi-view-dashboard",
        parent_id: null,
        url_slug: null,
        panel_id: null,
      },

      checked: false,
      nameRules: [(v) => !!v || "Name is required"],
      urlRules: [(v) => !!v || "Url is required"],
      addModulelabel: null,
      //pagination code
      perpage: 10,
      totRecords: null,
      page: 1,
      isLoading: true,
      fullPage: true,
      query: null,
      showPerPage: false,
      ///
    };
  },
  computed: {
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.page;
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    ///////
  },
  methods: {
       checkIfOperationExistForModule(type){
        return this.$utils.checkIfOperationExistForModule(this, type)
    },
    delete_mapping_sectors(item){
      let sector_name = item.sector_name
      var view  = this
      view.$swal
                .fire({
                title: "Are you sure ?",
                text:
                "This will remove record.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, remove it !",
                cancelButtonText: "No, cancel !",
                closeOnConfirm: false,
                closeOnCancel: false,
                }).then((result) => {
                if (result.isConfirmed) {
                  const path = view.$url("GET_ALL_SECTIONS")+"?sector_name="+sector_name;
                  this.$fetch({ requiresAuth: true, operation : 'view', vueScope: view  })
                  .delete(path)
                  .then((res) => {
                    this.isLoading = false;
                    this.getMobileDigital(1);
                    this.selected = []
                    this.$swal.fire({
                                      icon: "success",
                                      title: "Section Management",
                                      text: "Section has been deleted successfully",
                                  });
                                  view.sectormappingdialog = false
                    // this.pageNo = res.data.lastPage;

                      })
                      .catch((error) => {
                        view.$swal.fire({
                                  position: "bottom-end",
                                  icon: "error",
                                  title: "Something went wrong..",
                                });
                        console.error(error);
                      });
                } else {


                }
              });


    },
    deletesection(){
      var view = this
      if( view.selected.length > 0){
        view.$swal
                .fire({
                title: "Are you sure ?",
                text:
                "This will remove record.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, remove it !",
                cancelButtonText: "No, cancel !",
                closeOnConfirm: false,
                closeOnCancel: false,
                }).then((result) => {
                if (result.isConfirmed) {
                  let secid = []
                  for(var l in view.selected){
                                secid.push(this.selected[l].id)
                  }
                const path = view.$url("GET_ALL_SECTIONS")+"?section_id="+secid.join();
                this.$fetch({ requiresAuth: true, operation : 'view', vueScope: view  })
                  .delete(path)
                  .then((res) => {
                    this.isLoading = false;
                    this.getMobileDigital(1);
                    this.selected = []
                    this.$swal.fire({
                                      icon: "success",
                                      title: "Section Management",
                                      text: "Sector has been deleted successfully",
                                  });
                                  view.sectormappingdialog = false
                    // this.pageNo = res.data.lastPage;

                  })
                  .catch((error) => {
                    this.selected = []

                    view.$swal.fire({
                              position: "bottom-end",
                              icon: "error",
                              title: "Something went wrong",
                            });
                  });
                } else {
                  view.sectormappingdialog = false
                  this.selected = []
                }
                });
      }else{
        view.$swal.fire({
                    position: "bottom-end",
                    icon: "error",
                    title: "Please select record",
                  });
      }
    },
    save(){
      var view = this
      let data = {
        sector_id:view.sector_id,
        section_name:view.section_name
      }

      const path = view.$url("GET_ALL_SECTIONS");
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: view  })
        .post(path,data)
        .then((res) => {
          this.isLoading = false;
          this.getMobileDigital(1);

           this.$swal.fire({
                            icon: "success",
                            title: "Section Management",
                            text: "Section has been added successfully",
                        });
                        view.addsectiondialog = false
          // this.pageNo = res.data.lastPage;

        })
        .catch((error) => {
          view.$swal.fire({
                    position: "bottom-end",
                    icon: "error",
                    title: "Section already exists",
                  });
          console.error(error);
        });


    },
    savemapping(){
      var view = this

      let data = {
        sector_id:view.sector_id,
        mapping_section_id:view.mapping_section_id
      }

      const path = view.$url("GET_ALL_SECTIONS");
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: view  })
        .post(path,data)
        .then((res) => {
          this.isLoading = false;
          this.getMobileDigital(1);
          this.mappingsectordialog = false
          view.selected=[]

           this.$swal.fire({
                            icon: "success",
                            title: "Section Management",
                            text: "Sector has been mapped successfully",
                        });
                        view.addsectiondialog = false
          // this.pageNo = res.data.lastPage;

        })
        .catch((error) => {
          console.error(error);
        });


    },
    cancelmapping(){
      var view = this
      view.sector_id = ''
      view.section_id = ''
      this.mappingsectordialog = false

    },
    cancel(){
      var view = this
      view.sector_id = ''
      view.section_id = ''
      this.addsectiondialog = false

    },
    mapping_sectors(item){
      this.mapped_sectors_data = item.sectors
      this.sectormappingdialog = true

    },
    add_mapping_sector(item){
      var view = this
       this.mapped_sectors_data = item.sectors
       this.sector_id = []
      
    this.mapped_sectors_data.forEach(element=>{
      this.sector_id.push(parseInt(element.sector_id))
    })
      view.mapping_section_id=item.id
      view.mappingsectordialog = true


    },
    openaddsection(){
      var view = this
      view.section_name = ''
      view.addsectiondialog = true


    },

    getDataByPage(value) {
      console.log(value);
      this.page = value;
      this.getMobileDigital(this.page);
    },
    handlePerPage(value) {
      console.log(value);
      this.perpage = value;
      this.getMobileDigital(this.page);
    },
    open_img_popup(imgurl){
            var view = this
            window.open(imgurl, "", "toolbar=yes,scrollbars=yes,resizable=yes,top=100,,width=600,height=600");
        },
    paginate(n) {
      console.log(n);
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.getMobileDigital(this.page);
    },
    last(value) {
      console.log(value);
      this.page = this.lastPage;
      this.getMobileDigital(this.page);
    },

    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    validate() {
      this.$refs.form.validate();
    },

    getMobileDigital(page = "", query = "") {
      let check = this;
      const path = check.$url("GET_ALL_SECTIONS");
      check.isLoading = true;
      console.log(path + "checking path")
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: check  })
        .get(path)
        .then((res) => {
          check.isLoading = false;
          console.log(res);
          let payload   = res.data.payload
          this.total    =  payload.length;
          this.totRecords = res.data.totaldata;
          this.mobiledata = payload
          this.sectordata = res.data.sector_data.data
          // this.pageNo = res.data.lastPage;

        })
        .catch((error) => {
          console.error(error);
        });
    },

      addToDelete(e, atchid) {
      this.checkedthis = false;
      if (e.target.checked) {
        if (this.checked_Id.indexOf(e.target.value) == -1) {
          this.checked_Id.push(e.target.value);
        }
      } else {
        this.checked_Id.splice(
          this.checked_Id.indexOf(e.target.value),
          1
        );
      }
      let checkcedstr = this.checked_Id.join(",");
      this.checkcedstr = checkcedstr;
      console.log(this.checkcedstr);
    },
    


    selectAll: function () {
      let mobiledata = this.mobiledata;
      if (this.allSelected == true) {
        if (Array.isArray(mobiledata) && mobiledata.length) {
          this.selected = [];
          let i;
          for (i = 0; i < mobiledata.length; i++) {
            console.log(mobiledata[i]);
            this.selected.push(mobiledata[i][0]);
          }
        }
      } else if (this.allSelected == false) {
        this.selected = [];
      }
    },
    select: function () {
      this.allSelected = false;
    },
    submit() {
      this.$v.$touch();
    },
      searchModuleName: function () {
      this.getMobileDigital(this.page, this.query);
      console.log(this.query);
    },

     mobilemassupdatedialogStatus(o){
            var view = this
            view.mobilemassupdatedialog = false
            view.selected = []
            if(o =='save')view.getMobileDigital()
        },
        mobilemassproductupdate(){
            var view = this

            if(view.selected.length > 0){
              view.mobilemassupdatedialog = true
            }else{
              view.$swal.fire({
                    position: "bottom-end",
                    icon: "error",
                    title: "Please select ProductID",
                  });
            }
        },

  },
  mounted() {
    this.getMobileDigital(1);
  },
  watch: {
 '$store.state.Rawdata.current_active_module': function(o) {
    this.getMobileDigital(1);
//  if(o){
//  this.getMobileDigital(1);
//  }
 },
//  page: function (ob) {
//  this.getMobileDigital(ob);
//  },
 },





};
</script>
